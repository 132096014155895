@font-face {
	font-family: "Ragata";
	src: local("Ragata"), url("./fonts/Ragata.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"), url("./fonts/Montserrat.ttf") format("truetype");
}

@font-face {
	font-family: "InterRegular";
	src: local("InterRegular"),
		url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "InterMedium";
	src: local("InterMedium"), url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "InterBold";
	src: local("InterBold"), url("./fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "InterLight";
	src: local("InterLight"), url("./fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
	font-family: "GothamRounded";
	src: local("GothamRounded"),
		url("./fonts/GothamRounded-Book.otf") format("opentype");
}

@font-face {
	font-family: "GothamRoundedMedium";
	src: local("GothamRounded-Medium"),
		url("./fonts/GothamRounded-Medium.otf") format("opentype");
}

@font-face {
	font-family: "GothamRoundedBold";
	src: local("GothamRounded-Bold"),
		url("./fonts/GothamRounded-Bold.otf") format("opentype");
}

@font-face {
	font-family: "GothamRoundedLight";
	src: local("GothamRounded-Light"),
		url("./fonts/GothamRounded-Light.otf") format("opentype");
}

* {
	position: relative;
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
	margin: 0;
	padding: 0;
}

body {
	font-family: GothamRounded;
	font-size: 1rem;
	color: #050911;
	background-color: #f5f7f9;
	display: flex;
	flex-direction: column;
}

a {
	text-decoration: none;
}

a:not(.decorated) {
	color: #050911;
}

textarea {
	resize: none;
}

.test {
	border: 1px solid red;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.flex-row-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.full-height {
	height: 100% !important;
}

.full {
	width: 100% !important;
}

.half {
	width: 50% !important;
}

.fit {
	width: fit-content !important;
}

.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.group-item-details {
	width: calc(100% - 130px);
	/* border: 1px solid red; */
}

.hidden {
	display: none;
}

.block {
	display: block !important;
}

.outline {
	outline: 1px solid rgba(229, 231, 235, 0.5);
}

.border {
	border: 1px solid rgba(229, 231, 235, 0.5);
}

.border-top {
	border-top: 1px solid rgba(229, 231, 235, 0.5);
}

.border-left {
	border-left: 1px solid rgba(229, 231, 235, 0.5);
}

.border-bottom {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.border-right {
	border-right: 1px solid rgba(229, 231, 235, 0.5);
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.full-page {
	min-height: 100vh !important;
}

.text-regular-mont {
	font-family: Montserrat;
	font-weight: 400;
}

.text-medium-mont {
	font-family: Montserrat;
	font-weight: 500;
}

.text-bold-mont {
	font-family: Montserrat;
	font-weight: 700;
}

.text-light-mont {
	font-family: Montserrat;
	font-weight: 300;
}

.text-ragata {
	font-family: Ragata;
}

.text-regular-inter {
	font-family: InterRegular;
	/* font-weight: normal; */
}

.text-medium-inter {
	font-family: InterMedium;
	/* font-weight: 600; */
}

.text-bold-inter {
	font-family: InterBold !important;
	/* font-weight: 700; */
}

.text-light-inter {
	font-family: InterLight;
}

.text-regular-gotham {
	font-family: GothamRounded;
	/* font-weight: normal; */
}

.text-medium-gotham {
	font-family: GothamRoundedMedium;
	/* font-weight: 600; */
}

.text-bold-gotham {
	font-family: GothamRoundedBold !important;
	/* font-weight: 700; */
}

.text-light-gotham {
	font-family: GothamRoundedLight;
}

.text-white {
	color: #ffffff !important;
}

.text-light {
	color: #828488 !important;
}

.text-green {
	color: #c7883a !important;
}

.text-yellow {
	color: #f4cc4f !important;
}

.text-blue-dark {
	color: #0d163f !important;
}

.text-default {
	color: #050911 !important;
}

.text-orange {
	color: #ff7900 !important;
}

.text-orange-light {
	color: #ff9a6b !important;
}

.text-orange-dark {
	color: #d44513 !important;
}

.text-red {
	color: #ff3819 !important;
}

.title-lg {
	font-size: 2.5rem;
}

.title-xxl {
	font-size: 3.5rem;
}

.title-xl {
	font-size: 3rem;
}

.title-md {
	font-size: 2rem;
}

.text-xl {
	font-size: 1.5rem !important;
}

.text-lg {
	font-size: 1.125rem !important;
}

.text-md {
	font-size: 1rem !important;
}

.text-sm {
	font-size: 0.875rem !important;
}

.label-md {
	font-size: 0.75rem !important;
}

.label-sm {
	font-size: 0.563rem !important;
}

.opaque {
	opacity: 1 !important;
}

.opacity-1 {
	opacity: 0.1 !important;
}

.opacity-3 {
	opacity: 0.3 !important;
}

.opacity-5 {
	opacity: 0.5 !important;
}

.opacity-7 {
	opacity: 0.7 !important;
}

.upper {
	text-transform: uppercase;
}

.p-10 {
	padding: 10px;
}

.p-15 {
	padding: 15px;
}

.p-20 {
	padding: 20px;
}

.p-30 {
	padding: 30px;
}

.p-50 {
	padding: 50px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.btn-default-light {
	background-color: #faf0e4 !important;
	border-color: #faf0e4 !important;
	color: #424141 !important;
}

.btn-default-light:hover {
	background-color: #f6dec1 !important;
	border-color: #f6dec1 !important;
}

.btn-default-light:active {
	background-color: #f5d1a6 !important;
	border-color: #f5d1a6 !important;
}

.btn-default {
	background-color: #c7883a !important;
	border-color: #c7883a !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-green {
	background-color: #bbb569 !important;
	border-color: #bbb569 !important;
	outline: none !important;
	box-shadow: none !important;
}

/* .btn-default:hover,
.btn-default-outlined:hover {
	background-color: rgba(54, 105, 93, 0.9) !important;
	color: #fff;
} */

.btn-default:active,
.btn-default-outlined:active {
	background-color: #b07225 !important;
}

.btn-green:active,
.btn-green-outlined:active {
	background-color: #ada85f !important;
}

.btn-default-outlined {
	background-color: transparent !important;
	border: 1px solid #c7883a !important;
	color: #c7883a !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-default-outlined:hover {
	background-color: #c7883a !important;
	color: #fff !important;
}

.btn-yellow {
	background-color: #f7ce50 !important;
	color: #36695d;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-yellow:hover,
.btn-yellow-outlined:hover {
	background-color: rgba(247, 206, 80, 0.9) !important;
	color: #36695d;
}

.btn-yellow:active,
.btn-yellow-outlined:active {
	background-color: #e1bb4a !important;
	color: #36695d !important;
}

.btn-yellow-outlined {
	background-color: transparent;
	border: 2px solid #f7ce50 !important;
	color: #f7ce50;
	outline: none !important;
	box-shadow: none !important;
}

.btn-gray {
	background-color: #c8c8c8;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-gray-dark {
	background-color: #e0e1e3 !important;
	color: #050911 !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-gray-dark:hover {
	background-color: #d3d3d5 !important;
}

.btn-gray-dark:active {
	background-color: rgb(195, 195, 196) !important;
}

.btn-danger {
	background-color: #ff3819 !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-danger:hover {
	background-color: rgba(255, 56, 25, 0.9) !important;
}

.btn-danger:active {
	background-color: #e02f14 !important;
}

.btn-rounded {
	border-radius: 100px;
}

.btn-lg {
	height: 55px !important;
}

.btn-md {
	height: 48px !important;
	padding-left: 20px;
	padding-right: 20px;
}

.btn-md i {
	font-size: 1.2rem;
	margin-right: 10px;
}

.btn-md-loader {
	height: 28px;
	width: 28px;
	font-size: 0.8rem;
}

.btn-sm {
	height: 38px !important;
	padding-left: 20px;
	padding-right: 20px;
}

.bg-cover {
	background-size: cover !important;
	background-position: center !important;
}

.bg-default {
	background-color: #c7883a !important;
}

.bg-green {
	background-color: #bbb569 !important;
}

.bg-yellow {
	background-color: #fefaae !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-gray {
	background-color: #f5f7f9;
}

.bg-black {
	background-color: #050911;
}

.bg-blue {
	background-color: #3c57cc !important;
}

.bg-orange-light {
	background: #fad9bb;
}

.rounded-8 {
	border-radius: 8px !important;
}

.rounded-4 {
	border-radius: 4px !important;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 280px;
	z-index: 4;
	transition: 0.3s;
	box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
}

.main-nav-overlay {
	position: fixed;
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 3;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
}

.main-nav-overlay.active {
	display: inherit;
}

.content {
	padding: 118px 0 100px 280px;
}

.page-header {
	position: fixed;
	top: 0;
	left: 0;
	height: 80px;
	z-index: 3;
	padding-left: 280px;
	box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
}

.header-user {
	height: 40px;
	width: 40px;
	border-radius: 50%;
}

.pisara-container {
	padding: 7px;
	border-radius: 5px;
	/* transform: scale(0.8); */
	/* margin-right: 3px; */
	margin-right: 10px;
}

.pisara {
	height: 30px;
	width: 40px;
	border: 3px solid #fff;
	border-bottom: none !important;
}

.pisara::after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 4px;
	width: 10px;
	height: 3px;
	background-color: #fff;
}

.logo-text-container {
	margin-top: 8px;
}

.pisara-text {
	letter-spacing: 2px;
	font-size: 22px;
	line-height: 20px;
	opacity: 0.8;
}

.main-nav-header {
	height: 160px;
	/* padding-top: 20px; */
}

.main-nav-body {
	height: calc(100vh - 160px);
}

.main-nav-item {
	padding: 10px 10px 10px 40px;
	margin: 15px 0;
}

.main-nav-item.active {
	opacity: 1 !important;
}

.main-nav-item.active span,
.main-nav-item.active i {
	color: #c7883a !important;
}

.nav-icon {
	font-size: 1.2rem;
	margin-right: 12px;
}

.support-link-container {
	position: absolute;
	left: 0;
	bottom: 40px;
	width: 100%;
}

.support-link {
	width: 90%;
	border-radius: 15px;
}

.banner-img {
	height: 200px;
}

.hidden {
	display: none;
}

.header {
	position: fixed !important;
	top: 0;
	left: 0;
	height: 80px;
	z-index: 2;
	background-color: #36695d;
}

.header ul {
	list-style: none;
	padding: 0;
	margin: 0;
	--underline-width: 0; /* initial width */
	--underline-offset-x: 0; /* initial position */
}

.header li {
	width: 65px;
	text-align: center;
}

/* .header ul::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -5px;
	width: 20%;
	height: 2px;
	border-radius: 5px;
	background-color: #f4cc4f;
	transition: 500ms;
} */

/* .header.when ul::after {
	background-color: #438072 !important;
} */

/* .header ul.what::after {
	left: 20%;
}

.header ul.when::after {
	left: 40%;
}

.header ul.where::after {
	left: 60%;
}

.header ul.how::after {
	left: 80%;
} */

.landing-nav-item {
	display: inline;
	color: #fff !important;
	transition: 1000ms;
}

.section-container {
	height: fit-content !important;
}

.section {
	height: 100% !important;
}

.section.what {
	background: #85c4b5;
}

.header.what .landing-nav-item,
.header.where .landing-nav-item {
	color: #fff !important;
}

.landing-btn-container {
	width: 100%;
	margin-top: 60px;
}

.landing-btn-container button {
	margin: 10px;
	width: 200px;
}

.nav-logo {
	height: 120px;
}

.rsvp-logo {
	height: 100px;
}

.content-table-header {
	border-radius: 8px 8px 0 0;
}

.content-table-item {
	cursor: pointer;
	transition: 0.25s;
}

.content-table-item:last-of-type {
	border-radius: 0 0 8px 8px;
}

.content-table-item:hover {
	background-color: #f9f9f9 !important;
}

.guest-status {
	padding: 5px 10px;
	border-radius: 5px;
}

.guest-status.Confirmed,
.guest-status.Acknowledged,
.guest-status.Representative {
	background-color: #dbc37c;
}

.guest-status.Pending {
	background-color: #6e757c;
}

.guest-status.Invited {
	background-color: #828990;
}

.guest-status.Rejected,
.guest-status.Regret,
.guest-status.Cancelled {
	background-color: #e76f75;
}

.guest-status.Checked,
.guest-status.Partially {
	background-color: #4fb29b;
}

.login-logo {
	height: 100px;
}

.registration-logo {
	height: 100px;
}

.login-input-container {
	height: 48px;
	width: 100%;
	border-radius: 30px;
	background-color: #f2f2f2;
	padding: 0 20px;
}

.login-input {
	height: 54px;
	box-shadow: none !important;
	border: 2px solid #797979;
}

.login-input:focus {
	border: 2px solid #c7883a;
}

.reg-input {
	height: 42px;
	box-shadow: none !important;
	border: 1px solid #797979;
}

.reg-input:focus {
	border: 1px solid #c7883a;
}

.login-input-icon {
	font-size: 1.3rem;
}

.login-btn {
	border-radius: 30px;
}

.form-input:focus {
	border-color: #c7883a !important;
	box-shadow: none !important;
	outline: 0 none !important;
}

.registration-container {
	padding-top: 60px;
	padding-bottom: 60px;
}

.qr-container {
	padding-top: 20px;
	padding-bottom: 20px;
}

.upload-preview {
	width: 100%;
	height: 160px;
	background-color: #eee !important;
	border-radius: 8px;
	background-size: cover !important;
	background-position: center !important;
	cursor: pointer;
}

.upload-preview.candidate {
	width: 120px;
	height: 120px;
}

.upload-preview:active {
	transform: scale(0.99);
}

.downpayment-label {
	margin-top: 20px;
}

.thank-you-logo {
	height: 50px;
}

.pagination-btn {
	height: 30px;
	width: 30px;
	background-color: #f5f7f9;
	border-color: #f5f7f9;
	border-radius: 50%;
}

.refresh-btn {
	height: 38px;
	width: 38px;
	background-color: #f5f7f9;
	border-color: #f5f7f9;
	margin-right: 20px;
}

.filter-btn {
	position: absolute;
	right: 13px;
	height: 32px;
	width: 32px;
	border-radius: 50% !important;
	background-color: #fff;
	border-color: #fff;
}

.control-btn:not(:last-of-type) {
	margin-right: 20px;
}

.btn {
	border-radius: 4px;
}

.btn i {
	margin-right: 5px;
}

.pagination-btn i,
.refresh-btn i {
	font-size: 1.3rem;
	margin: 0 !important;
}

.info-modal .modal-body {
	height: calc(100vh - 250px);
	overflow-y: auto;
}

.message-modal .modal-body {
	height: calc(100vh - 250px);
	overflow-y: auto;
}

.filter-modal .modal-body {
	max-height: calc(100vh - 250px);
	overflow-y: auto;
}

.info-row .col {
	padding-top: 15px;
	padding-bottom: 15px;
}

.info-payment {
	height: 150px;
	cursor: pointer;
}

.img-modal .modal-body {
	padding: 0 !important;
	background-color: rgba(0, 0, 0, 0.8);
}

.img-open {
	max-width: 100%;
	max-height: 90vh;
	object-fit: cover;
}

.img-tools {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
	padding: 20px;
}

.img-tools button {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	margin-left: 10px;
}

.action-btn {
	min-width: 120px;
}

.search-container {
	border-radius: 8px;
	height: 42px;
	padding: 0 20px;
	width: 100%;
}

.search-result-counter {
	margin-left: 15px;
}

.search-container input {
	padding-left: 20px;
	padding-right: 40px;
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.search-icon {
	position: absolute;
	left: 13px;
}

.page-loader-icon {
	font-size: 0.5rem !important;
}

.search-form-sm {
	margin-bottom: -52px;
}

.search-container-sm {
	border-radius: 8px;
	height: 58px;
	padding: 0 20px;
	width: 100%;
}

.search-container-sm input {
	padding-left: 20px;
	padding-right: 40px;
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.filters-container {
	margin-top: 30px;
	margin-bottom: 20px;
}

.filters-label {
	margin-right: 10px;
}

.filter-item {
	display: inline-block;
	height: 30px;
	padding: 2px 30px 2px 10px;
	margin-right: 15px;
	margin-bottom: 10px;
}

.remove-filter {
	position: absolute;
	right: 5px;
	font-size: 1.1rem !important;
}

.upload-preview.border-danger,
.form-input.border-danger {
	border: 1px solid red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.reg-qr {
	height: 250px;
}

#root {
	height: 100vh !important;
}

.dropdown-default a:active {
	background-color: #f6d4aa !important;
	color: #050911 !important;
}

.method-copy-btn {
	margin-top: 20px;
}

/* .log-row:not(:last-of-type) {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
} */

.message-content {
	height: 200px;
}

.empty-label {
	height: calc(100vh - 400px);
}

.variable {
	margin-right: 10px;
}

.candidate {
	width: 33.33%;
}

.candidate-photo {
	height: 50px;
	width: 50px;
	margin-right: 15px;
	background-size: cover !important;
	background-position: center !important;
}

.candidate-vote-img {
	height: 150px;
	width: 150px;
	border-radius: 10px;
	background-size: cover !important;
	background-position: center !important;
}

.vote-cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(5, 9, 17, 0.9);
	border-radius: 10px;
	display: none;
	z-index: 3;
}

.vote-cover.active {
	display: flex;
}

.vote-loader {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 4;
	display: none;
}

.vote-loader.active {
	display: flex;
}

.vote-spinner {
	height: 50px;
	width: 50px;
}

.guest {
	min-height: 150px;
}

.guest.instructions {
	height: 200px;
}

.guest.empty {
	border: 3px solid #d3d4d6;
}

.guest.error {
	border: 3px solid #cb444a;
}

.guest.success {
	border: 3px solid #509f4e;
}

.scanner-logo {
	height: 170px;
}

.scanner-btn-container {
	position: absolute;
	bottom: 20px;
}

.scanner-home {
	padding-bottom: 50px;
}

.btn-loader {
	height: 20px;
	width: 20px;
	border-width: 2px;
}

.snackbar-container {
	width: fit-content !important;
	height: fit-content !important;
	padding: 0 !important;
}

.snackbar-container > div {
	margin: 0 !important;
}

.snackbar {
	padding: 12px 15px;
	width: 300px;
}

.snackbar-content {
	word-wrap: break-word;
	overflow-wrap: break-word;
	width: calc(100% - 30px);
}

.snackbar.success {
	background-color: #53a551;
}

.snackbar.error {
	background-color: #cb444a;
}

.snackbar-icon {
	font-size: 1.5rem;
	font-weight: bold;
	margin-right: 10px;
}

textarea.form-input {
	height: 100px;
}

.back-btn i {
	font-size: 1.2rem;
	margin-right: 2px;
}

.back-btn > span {
	letter-spacing: 0.05rem;
}

.back-btn:hover > i,
.back-btn:hover > span {
	opacity: 1 !important;
}

.contact-icon {
	height: 45px;
	width: 45px;
	background-color: #dadee7;
	margin-right: 12px;
}

.contact-icon-loader {
	margin-right: 12px;
}

.no-select {
	user-select: none;
}

.info-tab-item {
	user-select: none;
	color: #050911 !important;
}

.info-tab-item.active {
	background-color: transparent !important;
	border-bottom: 1px solid #f5f7f9 !important;
	font-family: GothamRoundedMedium;
}

.info-tab-item.light.active {
	border-bottom: 1px solid #fff !important;
}

.info-control-btn {
	padding: 0 15px !important;
	height: 34px !important;
	margin-left: 10px;
}

.modal-control-btn {
	padding: 0 15px !important;
	height: 34px !important;
	margin-left: 10px;
	background-color: transparent;
	border: none !important;
}

.info-log {
	padding-top: 10px;
	padding-bottom: 10px;
	cursor: pointer;
	transition: 0.25s;
	overflow: hidden;
}

/* .info-log:not(:last-of-type)::after {
	content: "";
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	height: 1px;
	background-color: rgba(229, 231, 235, 1);
} */

.info-log:hover {
	background-color: #e8ebf1 !important;
}

.rti--container {
	display: block !important;
	min-height: 38px !important;
	padding: 0.375rem !important;
	--rti-main: none !important;
	outline: none !important;
	--rti-tag-remove: #fff !important;
}

.rti--tag {
	padding-left: 13px !important;
	display: block !important;
	width: fit-content !important;
	margin-bottom: 7px;
}

.rti--tag button {
	color: #fff !important;
	padding-right: 10px !important;
	font-size: 0.8rem;
	margin-top: 1px;
}

.rti--input {
	width: 100% !important;
}

.rti--container:has(.rti--input:focus) {
	border: 1px solid #c7883a !important;
}

.rti--container:has(.rti--input.error) {
	border: 1px solid red !important;
}

.email-preview {
	max-width: calc(100% - 70px);
}

.email-badge {
	margin-left: 5px;
	min-width: 40px;
	padding: 2px 5px;
	background-color: #fff2e3;
}

.tag-item {
	padding: 5px 35px 5px 10px !important;
	margin-right: 10px;
	display: inline-block;
	margin-bottom: 10px !important;
}

.tag-remove-btn {
	position: absolute;
	right: 2px;
	top: 0;
	height: 100%;
	background-color: transparent;
	border: none !important;
	font-size: 1.2rem;
	padding-bottom: 0 !important;
	padding-top: 2px !important;
}

.info-tag {
	padding: 2px 10px;
	margin-right: 10px;
	display: inline-block;
	margin-bottom: 10px;
}

.tag-suggestion ul {
	padding: 0 !important;
}

.tag-suggestion li {
	list-style-type: none !important;
	padding: 2px 0.375rem !important;
}

.tag-suggestion li.active {
	background-color: #f6d4aa;
}

.event-guests-header,
.logs-header {
	padding-bottom: 15px;
}

.dropdown-toggle.no-arrow::after {
	display: none !important;
}

.guest-filter-type {
	padding: 7px 20px;
	border-radius: 20px;
	margin-right: 10px;
	cursor: pointer;
}

.guest-filter-type.active {
	border: 1px solid #d2cc78 !important;
}

.guest-filter-type:not(.active):hover {
	background-color: #fcfae2;
}

.guest-filter-empty {
	height: 100px;
}

.guest-filter-container {
	padding-bottom: 15px;
}

.event-guest {
	padding: 10px 7px;
	cursor: pointer;
	transition: 0.25s;
}

.event-guest:not(.loader):not(.content-table-header):hover {
	background-color: #e8ebf1;
}

.guest-option-btn {
	border: none;
	background-color: #fff;
}

.dropdown-item.text-danger:active {
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.shadow-form {
	box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
}

.rsvp-form {
	border-radius: 15px;
}

.form-floating > label {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.lbl {
	/* position: absolute;
	right: 0; */
	/* margin-top: 5px; */
	margin-top: -1px;
	margin-right: 10px;
	height: 16px;
	width: 37px;
	background: #c8c7c7;
	border-radius: 100px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.lbl.asset-lbl {
	margin-top: 5px;
}

.lbl::after {
	position: absolute;
	left: -2px;
	top: -3px;
	width: 22px;
	height: 22px;
	border-radius: 100px;
	background: white;
	border: 1px solid #ddd;
	box-shadow: 0px 3px 3px rgba(#000, 0.05);
	content: "";
	transition: all 0.3s ease;
}

.lbl:active::after {
	transform: scale(1.15, 0.85);
}

.cbx:checked ~ label {
	background: #c7883a !important;
}

.cbx:checked ~ label::after {
	left: 16px;
	/* background: #519efd;
	border: 1px solid #519efd; */
}

.cbx:disabled ~ label {
	background: #d5d5d5;
	border: 1px solid #d5d5d5;
	pointer-events: none;
}

.cbx:disabled ~ label::after {
	background: #bcbdbc;
	border: 1px solid #bcbdbc;
}

.invite-options-container {
	width: 100%;
	height: 50px;
	padding: 0 20px;
}

.invite-option {
	margin-right: 40px;
}

.rsvp-option {
	padding: 30px 0;
}

.rsvp-option:first-of-type {
	padding-top: 10px !important;
}

/* .rsvp-option:last-of-type {
	padding-bottom: 0 !important;
} */

.rsvp-option:not(:last-of-type) {
	border-bottom: 1px solid rgb(229, 231, 235);
}

.rsvp-option-input-container {
	margin-top: 20px;
}

.rsvp-option-input-container input {
	height: 48px;
}

.rsvp-modal .modal-body {
	max-height: calc(100vh - 250px);
	overflow: auto;
}

.event-sub-guest {
	padding-top: 10px;
	padding-bottom: 10px;
	outline: 1px solid #eee;
}

.overlay-modal-backdrop {
	z-index: 1060;
}

.overlay-modal {
	z-index: 1070;
}

.checkin-modal .modal-body {
	padding: 0 !important;
}

.checkin-guest-member {
	cursor: pointer;
	transition: 0.3s;
	padding: 0 20px;
	height: 80px;
}

.checkin-guest-member:not(:last-of-type) {
	border-bottom: 1px solid rgba(229, 231, 235, 1);
}

.checkin-guest-member:not(.active):hover {
	background-color: #f4f4f4;
}

.checkin-guest-member.active {
	background: #fff2e3;
}

.checkin-guest-check {
	height: 20px;
	width: 20px;
	margin-right: 15px;
	border: 2px solid #c7883a;
}

.checkin-guest-check i {
	font-weight: 600;
}

.checkin-guest-member.active .checkin-guest-check {
	background-color: #c7883a;
}

.event-guest-name {
	padding-right: 60px;
}

.event-stat {
	height: 120px;
}

.scanner-modal .modal-dialog {
	height: calc(100% - 40px);
}

.scanner-modal .modal-content {
	height: calc(100% - 20px);
}

.scanner-modal .modal-body {
	padding: 0;
	overflow-y: auto;
}

.scanner-guest-member {
	cursor: pointer;
	transition: 0.3s;
	padding: 0 20px;
	height: 80px;
}

.scanner-guest-member {
	border-bottom: 1px solid rgba(229, 231, 235, 1);
}

.scanner-guest-member.active {
	background: #fff2e3;
}

.scanner-guest-member.checked-in {
	background: #ddd;
}

.scanner-guest-member.checked-in span {
	opacity: 0.5;
}

.scanner-guest-member.checked-in .checkin-guest-check {
	border: 2px solid #333 !important;
	opacity: 0.5;
}

.scanner-guest-member.checked-in .checkin-guest-check i {
	color: #333 !important;
}

.scanner-guest-check i {
	font-weight: 600;
}

.scanner-guest-member.active .checkin-guest-check {
	background-color: #c7883a;
}

.selected-client-delete {
	font-weight: 700;
}

.selected-guest {
	padding-top: 10px;
	padding-bottom: 10px;
}

.selected-guest:hover {
	background-color: #e8ebf1 !important;
}

.rsvp-loading {
	height: 150px;
}

.guest-loader {
	margin-top: 15px;
	margin-bottom: 50px;
}

.pagination-bottom .pagination-next-btn {
	margin-right: 0 !important;
}

.batch-loader-container {
	height: 5px;
	width: 100%;
}

.batch-loader-container > div {
	height: 100%;
	/* background-color: #c7883a; */
	animation: blink normal 1.5s infinite ease-in-out;
}

.template-loader {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 5;
}

.event-guest-pagination .pagination-btn {
	border: none !important;
}

.event-guest-pagination .pagination-btn:not(:hover) {
	background-color: #fff;
}

.event-guest-pagination .pagination-next-btn {
	margin-right: 10px !important;
}

.event-guest-refresh {
	border: none !important;
	margin-right: 10px !important;
}

.event-guest-refresh:not(:hover) {
	background-color: #fff;
}

.scanner-container {
	padding-top: 20px;
	padding-bottom: 100px;
}

.scanner-search-container {
	height: 48px;
	border-radius: 8px;
	box-shadow: 0px 0px 15px -11px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 15px -11px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 15px -11px rgba(0, 0, 0, 0.75);
}

.scanner-search-container input {
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
	padding-left: 60px;
}

.scanner-search-btn {
	position: absolute;
	right: 0;
	width: fit-content !important;
	height: 100% !important;
	padding: 0 !important;
	padding-right: 20px !important;
	z-index: 5;
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.scanner-search-btn i {
	margin: 0 !important;
}

.nav-toggle {
	margin-right: 30px;
}

.fry::before {
	content: "";
	position: absolute;
	top: -8px;
	width: 30px;
	height: 2px;
	border-radius: 4px;
	background-color: #b5782d;
}

.fry {
	width: 20px;
	height: 2px;
	border-radius: 4px;
	background-color: #b5782d;
}

.fry::after {
	content: "";
	position: absolute;
	bottom: -8px;
	width: 25px;
	height: 2px;
	border-radius: 4px;
	background-color: #b5782d;
}

.scanner-event {
	padding: 20px 15px;
}

.scanner-event-icon {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	margin-right: 15px;
	background-color: #e8b475;
}

.scanner-event-info {
	width: calc(100% - 70px);
}

.scanner-nav {
	position: fixed;
	top: 0;
	left: -250px;
	padding-top: 20px;
	height: 100vh;
	width: 250px;
	z-index: 7;
	background-color: #fff;
	transition: 0.3s;
	box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
}

.scanner-nav-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 6;
	display: none;
}

.scanner-nav-backdrop.active {
	display: flex;
}

.scanner-nav.active {
	left: 0 !important;
}

.scanner-nav-logo {
	height: 100px;
}

.scanner-nav-body {
	padding-top: 20px;
}

.scanner-nav-item {
	padding: 5px 5px 5px 30px;
	margin: 10px 0;
}

.scanner-nav-item.active {
	opacity: 1 !important;
}

.scanner-nav-item.active span,
.scanner-nav-item.active i {
	color: #c7883a !important;
}

.master-loader {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}

.loader-logo {
	height: 170px;
}

.invitation-input {
	min-height: 200px;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 75% !important;
	padding: 20px 10px;
}

.email-editor-container {
	min-width: 100% !important;
	border: 1px solid rgba(229, 231, 235, 1);
	border-radius: 4px !important;
}

.email-editor-container iframe {
	min-width: 100% !important;
	width: 100% !important;
	border-radius: 4px !important;
}

.template-modal .modal-dialog {
	min-width: 900px !important;
	max-width: 100% !important;
	width: 75% !important;
}

.template-frame {
	min-height: 500px;
}

.modal label {
	font-family: GothamRoundedMedium;
}

.code-btn {
	width: 50px;
}

.code-btn i {
	font-weight: 800;
	margin: 0 !important;
}

.order-item {
	overflow: hidden;
}

.order-item:last-of-type {
	margin-bottom: 0 !important;
}

.order-item-img {
	height: 150px;
	width: 170px;
	margin-right: 15px;
	background-color: #f5f7f9 !important;
}

.order-item-toggle {
	padding: 15px;
}

.order-item-toggle:hover {
	cursor: pointer;
	background-color: #f9f9f9 !important;
}

.order-icon {
	position: absolute;
	right: 15px;
	font-size: 1.5rem;
}

.order-icon.active {
	transform: rotate(180deg);
}

.order-info-container {
	padding: 20px 15px;
	border-top: 1px solid rgba(229, 231, 235, 0.5);
	display: none;
}

.order-info-container.active {
	display: block !important;
}

.wo-attachments-row {
	margin-bottom: 35px;
}

.file-upload {
	float: left;
	height: 100px;
	width: 100px;
	margin-right: 15px;
	margin-bottom: 15px;
}

.file-btn {
	float: left;
	height: 100px;
	width: 100px;
	margin-right: 15px;
	cursor: pointer;
	background: transparent !important;
}

.file-btn:hover {
	background-color: #eee !important;
}

.upload-progress-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 2;
}

.upload-progress-bar {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 3px;
	background-color: #c7883a;
	transition: 0.3s;
	transition-timing-function: ease-in;
	z-index: 2;
}

.upload-progress-pulse {
	height: 100%;
	width: 100%;
	animation: pulse 2s linear infinite !important;
}

.file-delete-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	display: none;
}

.file-delete-overlay > i {
	cursor: pointer;
}

.file-upload:hover .file-delete-overlay {
	display: flex;
}

.remove-item-btn {
	height: 28px !important;
	width: 170px;
}

.wo-status,
.client-status {
	padding: 2px 13px;
	border-radius: 4px;
	color: #fff;
}

.wo-status.Ongoing {
	background-color: #dbc37c;
}

.wo-status.New,
.client-status.Active {
	background-color: #4fb29b;
}

.wo-status.Cancelled {
	background-color: #e76f75;
}

.wo-status.Completed,
.client-status.Pending {
	background-color: #6e757c;
}

.wo-action-container {
	padding-top: 20px;
}

.wo-action-btn {
	min-width: 150px;
}

.comment-input {
	height: 100px;
	padding-top: 13px;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.comment-input-container:has(.comment-input:focus) {
	border-color: #c7883a !important;
}

.comment-control {
	padding: 10px 13px;
}

.wo-update-title {
	margin-bottom: 5px;
}

.notif-status-icon {
	font-size: 1rem;
	margin-left: 8px;
}

.notif-status-icon.notify {
	color: #4fb29b;
}

.display-sm {
	display: none;
}

.page-banner {
	margin-bottom: 50px;
	background: url("assets/wave-haikei.svg");
}

.banner-stat {
	background-color: #dfb27b;
	height: 120px;
}

.content-item-sm {
	padding: 15px;
}

.content-item-title {
	margin-bottom: 5px;
}

.content-item-sub {
	padding-top: 15px;
}

.fab {
	position: fixed;
	right: 50px;
	bottom: 50px;
	padding: 15px 20px;
	border-radius: 30px;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2) !important;
	background-color: #fff !important;
	border: 1px solid #c7883a !important;
	color: #c7883a !important;
}

.fab:active {
	background-color: #f4dfc5 !important;
	box-shadow: none !important;
}

.fab > i {
	font-size: 1.2rem;
	line-height: 1.2rem;
	margin-right: 8px !important;
}

.info-group {
	padding: 20px 15px;
}

.main-nav-header-sm {
	height: 80px;
}

.main-nav-user {
	border-radius: 4px;
	padding: 10px;
}

.empty-state {
	height: fit-content !important;
	border-radius: 0 0 8px 8px;
}

.empty-text {
	margin-top: 30px;
}

.comment-btn {
	min-width: 150px;
}

.report-loader {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 3;
	width: 380px;
	border: 1px solid #eee;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
	/* border-radius: 5px 5px 0 0; */
}

.report-loader-header {
	height: 40px;
	width: 100%;
	padding: 0 10px;
	background-color: #38383a;
	/* border-radius: 5px 5px 0 0; */
}

.report-loader-title {
	font-size: 0.9rem;
}

.report-loader-close {
	position: absolute;
	right: 10px;
	cursor: pointer;
}

.report-loader-body {
	width: 100%;
}

.report-loader-item {
	padding: 20px;
	width: 100%;
}

.report-loader-item:not(:last-of-type) {
	border-bottom: 1px solid #eee;
}

.report-loader-name {
	width: 70%;
}

.report-loader-wheel {
	position: absolute;
	right: 20px;
	width: 27px;
	height: 27px;
	border: 3px solid #e8dbcb;
	border-bottom-color: #c7883a;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.report-loader-download {
	position: absolute;
	right: 10px;
	color: #c7883a;
	cursor: pointer;
	border-radius: 50%;
	border: 1px solid #eee;
	padding: 5px;
}

.report-loader-download:hover {
	background-color: #eee;
}

.report-loader-download:active {
	transform: scale(0.95);
}

/* OTHER SCREENS */

@media screen and (max-width: 992px) {
	.main-nav {
		width: 300px;
		left: -300px;
	}

	.main-nav.active {
		left: 0;
	}

	.main-nav-item {
		padding: 0 0 0 10px;
		margin: 0 0 25px 0;
	}

	.main-nav-item span {
		font-size: 0.8rem !important;
	}

	.display-lg {
		display: none;
	}

	.display-sm {
		display: inherit;
	}

	.content {
		padding: 80px 0 100px 0;
	}

	.page-header {
		padding-left: 0;
	}

	.nav-logo-sm {
		height: 50px;
		margin-right: 8px;
	}

	.page-header-title {
		font-size: 0.875rem;
	}

	.content-header {
		padding-top: 20px;
		padding-bottom: 30px;
	}

	.content-header {
		background-color: #ffe5c3;
		margin-bottom: 0 !important;
	}

	.info-group-container {
		padding: 0 !important;
		max-width: 100vw !important;
	}

	/* .info-group {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
	} */

	.client-header-content {
		display: block;
	}

	.client-header-title {
		margin-bottom: 10px !important;
	}

	.info-group > div {
		width: 100%;
		max-width: 720px;
		padding-right: calc(var(--bs-gutter-x) * 0.5);
		padding-left: calc(var(--bs-gutter-x) * 0.5);
		margin-right: auto;
		margin-left: auto;
	}

	.content {
		padding-bottom: 0;
	}

	.info-group {
		min-height: calc(100vh - 250px);
		border-radius: 0 !important;
	}

	.client-updates-row {
		margin-bottom: 30px;
	}

	.info-control-btn-sm {
		padding: 0 !important;
		height: 34px !important;
		margin-left: 10px;
		background-color: transparent !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
	}

	.nav-toggle {
		margin-right: 25px;
		height: 70px;
		width: 30px;
	}

	.file-upload {
		height: 80px;
		width: 80px;
		margin-right: 10px;
		margin-bottom: 10px;
	}

	.file-btn {
		float: left;
		height: 80px;
		width: 80px;
		margin-right: 10px;
		cursor: pointer;
		background: transparent !important;
	}
}

@media screen and (max-width: 768px) {
	.banner-stat {
		height: fit-content;
		padding: 14% 15px;
		border-radius: 10px !important;
	}

	/* .page-header-title {
		font-size: 0.8rem;
	} */

	.banner-stat .title-md {
		font-size: 1.5rem;
	}

	.banner-stat .text-sm {
		font-size: 0.8rem !important;
	}

	.stat-text-container {
		width: 100%;
		text-align: left;
	}

	.fab {
		right: 20px;
		bottom: 30px;
	}

	.info-group > div {
		max-width: 540px;
		--bs-gutter-x: 1.5rem;
	}

	.back-btn-row {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 576px) {
	.nav-logo-sm {
		height: 40px;
		margin-right: 8px;
	}

	.page-header-title {
		display: none;
	}

	.info-group > div {
		max-width: 100% !important;
		--bs-gutter-x: 0;
	}

	.login-title {
		font-size: 0.875rem !important;
	}
}

@keyframes blink {
	0% {
		background-color: rgba(199, 136, 58, 1);
	}
	50% {
		background-color: rgba(199, 136, 58, 0.6);
	}
	100% {
		background-color: rgba(199, 136, 58, 1);
	}
}

@keyframes pulse {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 0.3;
	}
	50% {
		opacity: 0.6;
	}
	75% {
		opacity: 0.3;
	}
	100% {
		opacity: 0;
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
